<template>
  <div>
    <Row :gutter="5" class="m-b-5">
      <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="6" :md="5" class="m-b-5">
        <Select size="small" v-model="agreementQuery.companyId" placeholder="所属公司" clearable @on-change="initdepartments">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
        </Select>
      </i-col>
         <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="6" :md="5" class="m-b-5">
                <Select size="small" v-model="agreementQuery.departmentId" placeholder="所属部门" clearable @on-change="getChannelUserList">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
      <i-col v-if="isAuth('Agreement_Manage_View')"  :xs="24" :sm="6" :md="5" class="m-b-5">
        <Select size="small" v-model="agreementQuery.userId" placeholder="归属销售" clearable>
          <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="6" :md="5" class="m-b-5">
        <i-select  size="small" placeholder="状态" v-model="agreementQuery.status" clearable>
            <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="6" :md="5" class="m-b-5">
        <i-input  size="small" placeholder="关键字：客户名称、投放品牌、备注" v-model="agreementQuery.keyword">
        </i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="4" class="m-b-5">
        <i-button size="small" type="primary" icon='ios-search' @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>

    <i-table stripe :data="list" :columns="titles"></i-table>
    <div class="paging_style">
      <Page size="small"  :current="agreementQuery.pageNumber"  :page-size="agreementQuery.pageSize" :total="total" @on-change="handlePageChanged" show-total show-elevator></Page>
    </div>

  </div>
</template>

<script>
import { confirmationPage, delConfirmation } from '@/api/order/confirmation'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { formatContractStatus } from '@/utils/tagStatus'
import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  data () {
    return {
      channellist: [],
      departments: [],
      channelUsers: [],
      total: 0,
      list: [],
      titles: [
        { title: '客户', key: 'advertiserName' },
        { title: '发布品牌', key: 'brandName' },
        {
          title: '起止日期',
          render: (h, data) => {
            if (data.row.startDate) {
              return h('div', [
                h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
              ])
            } else {
              return h('span', '--')
            }
          }
        },
        { title: '备注', key: 'desc' },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatContractStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const updateButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleEdit(data.row.confirmationId)
                }
              }
            }, '编辑')
            const deleteButton = h('a', {
              style: {
                marginRight: '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  this.handleDelete(data.row)
                }
              }
            }, '删除')
            const detailButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleDetail(data.row.confirmationId)
                }

              }
            }, '详情')
            if (this.isAuth('Agreement_Edit')) {
              if (data.row.status === 0) {
                return h('div', [detailButton, updateButton, deleteButton])
              } else if (data.row.status === 6) {
                return h('div', [detailButton, updateButton])
              }
            }

            return h('div', [detailButton])
          }
        }

      ],
      statusArray: []
    }
  },
  created () {
    this.agreementQuery.userId = this.isAuth('Agreement_Manage_View') ? null : this.$store.getters.token.userInfo.userId
    this.initStatusArray()
    this.initPage()
  },
  computed: {
    agreementQuery () {
      return this.$store.state.contractQuery.agreementQuery
    }
  },
  methods: {
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: 0, name: '草稿' },
        { id: -1, name: '已废弃' }
      ]
      const manageStatus = [
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' },
        { id: 7, name: '已完成' }
      ]
      if (that.isAuth('Agreement_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    },
    initPage () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (array.length > 1) {
          this.agreementQuery.companyId = null
        } else {
          this.agreementQuery.companyId = this.$store.getters.token.userInfo.companyId
        }
        this.initdepartments()
      })
    },
    initdepartments () {
      if (this.agreementQuery.companyId) {
        getCompanyChild({ companyId: this.agreementQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          this.agreementQuery.departmentId = null
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.agreementQuery.companyId = this.isAuth('Agreement_Manage_View') ? this.agreementQuery.companyId : this.$store.getters.token.userInfo.companyId
      if (this.agreementQuery.companyId) {
        const data = {
          companyId: this.agreementQuery.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.agreementQuery.userId = ''
      }
      this.getAgreementList()
    },
    getAgreementList () {
      this.$store.commit('set_query_agreementQuery', this.agreementQuery)
      confirmationPage(this.agreementQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleDetail (agreementId) {
      this.$emit('on-detail', agreementId)
    },
    handleEdit (agreementId) {
      this.$emit('on-edit', agreementId)
    },
    handleDelete (agreement) {
      this.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除该确认单，点击确认后将删除',
        onOk: () => {
          delConfirmation({ confirmationId: agreement.confirmationId }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({
                desc: '确认单删除成功'
              })
              this.handleSearch()
            }
          })
        }

      })
    },
    handlePageChanged (page) {
      this.agreementQuery.pageNumber = page
      this.getAgreementList()
    },
    handleSearch () {
      this.agreementQuery.pageNumber = 1
      this.getAgreementList()
    },
    formatSchuedle (data) {
      return GetCurrentSchedule(data.startDate, data.endDate)
    },
    formatContractStautsColor (status) {
      let color = 'Gray'
      if (status === 0) { // 草稿
        color = 'default'
      } else if (status === 3) { // 创建合同中
        color = 'SeaGreen'
      } else if (status === 4) { // 待审批
        color = 'Orange'
      } else if (status === 5) { // 已通过
        color = 'SeaGreen'
      } else if (status === 6) { // 已拒绝
        color = '#ed4014'
      } else if (status === 9) { // 已归档
        color = 'Black'
      }

      return color
    }

  }
}
</script>
